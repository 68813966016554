import axios from 'axios'

const baseURL = `${process.env.REACT_APP_API_URL}/prize-hub`

const api = axios.create({
  baseURL,
})

api.interceptors.request.use(async (config) => {
  if (config.headers) {
    config.headers['x-api-key'] = process.env.REACT_APP_API_KEY || ''
  }

  return config
})

export default api
