import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { GetPrizeStocksInterface } from '~/models/interfaces/services'

import { Services } from '~/services'

import { Loading, ScratchCardComponent, ScratchedWon } from '~/components'

import '../../index.css'
import viacertaLogo from '../../assets/img/raspadinha/logo_viacerta.png'
import { svgPaths } from '~/assets'
import { ScratchCardContainer } from './ScratchCardStyles'

const campaignContents = {
  default: { backgroundImage: svgPaths.defaultBackground },
}

const ScratchCardPage = () => {
  const { slug, contractNumber } = useParams<{
    slug?: string
    contractNumber?: string
  }>()

  const [prizeStocksState, setPrizeStocksState] = useState<{
    isLoading: boolean
    data: GetPrizeStocksInterface
  }>({
    isLoading: true,
    data: {
      prizeDrawType: '',
      productExternalClassificationTypeEnum: '',
      rafflePrize: [],
    },
  })

  const getMaxRafflePrize = () => {
    return prizeStocksState.data.rafflePrize.reduce(
      (max, curr) =>
        Number(curr.description.replace(/[^0-9]/g, '')) >
        Number(max.description.replace(/[^0-9]/g, ''))
          ? curr
          : max,
      prizeStocksState.data.rafflePrize[0],
    )
  }

  useEffect(() => {
    if (!slug || !contractNumber) return

    const fetchPrizeStocks = async () => {
      setPrizeStocksState((prev) => ({ ...prev, isLoading: true }))

      const { data } = await Services.getPrizeStocks(contractNumber, slug)

      setPrizeStocksState({
        isLoading: false,
        data,
      })
    }

    fetchPrizeStocks()
  }, [contractNumber, slug])

  return (
    <ScratchCardContainer backgroundImage={campaignContents.default.backgroundImage}>
      <header>
        <ScratchedWon />
      </header>

      <main>
        {prizeStocksState.isLoading ? (
          <Loading />
        ) : (
          <ScratchCardComponent
            value={
              prizeStocksState.data.rafflePrize.filter((prize) => prize.isAwarded)[0].description
            }
            maxValue={getMaxRafflePrize().description}
            contractNumber={contractNumber || ''}
            slug={slug || ''}
          />
        )}
      </main>

      <footer>
        <img src={viacertaLogo} alt="VIACERTA Banking" />
      </footer>
    </ScratchCardContainer>
  )
}

export default ScratchCardPage
