import React from 'react'
import * as Primitive from '@radix-ui/react-tooltip'

import { SxProps } from '~/assets/styles'
import { TooltipArrowAtom, TooltipContentAtom, TooltipContentAtomProps } from './styles'

export type TooltipProps = {
  content?: React.ReactNode
  side?: Primitive.TooltipContentProps['side']
  noPortal?: boolean
  slotProps?: {
    portal?: Primitive.TooltipPortalProps
    content?: TooltipContentAtomProps
    arrow?: Primitive.TooltipArrowProps
  }
} & Primitive.TooltipProps & SxProps

export const Tooltip = (props: TooltipProps) => {
  const {
    children, content, side, slotProps, sx, noPortal, ...rest
  } = props

  const contentComponent = (
    <TooltipContentAtom
      side={side || slotProps?.content?.side}
      sx={sx}
      data-testid="tooltip-content"
      {...slotProps?.content}
    >
      {content}
      <TooltipArrowAtom {...slotProps?.arrow} />
    </TooltipContentAtom>
  )

  return (
    <Primitive.Root {...rest}>
      <Primitive.Trigger asChild>{children}</Primitive.Trigger>
      {noPortal ? (
        contentComponent
      ) : (
        <Primitive.Portal {...slotProps?.portal}>{contentComponent}</Primitive.Portal>
      )}
    </Primitive.Root>
  )
}

export type TooltipRootProps = {
  slotProps?: {
    portal?: Primitive.TooltipPortalProps
  }
} & Primitive.TooltipProps

type Elements = {
  content?: React.ReactElement
  rest: React.ReactNode[]
}

export const TooltipRoot = (props: TooltipRootProps) => {
  const { children, slotProps, ...rest } = props

  const elements: Elements = {
    rest: [],
  }

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return
    if (child.type === TooltipContent) elements.content = child
    else elements.rest.push(child)
  })

  return (
    <Primitive.Root {...rest}>
      {elements.rest}
      <Primitive.Portal {...slotProps?.portal}>{elements.content}</Primitive.Portal>
    </Primitive.Root>
  )
}

export type TooltipContentProps = {
  slotProps?: {
    arrow?: Primitive.TooltipArrowProps
  }
} & Primitive.TooltipContentProps

export const TooltipContent = ({ children, slotProps, ...props }: TooltipContentProps) => (
  <TooltipContentAtom {...props}>
    {children}
    <TooltipArrowAtom {...slotProps?.arrow} />
  </TooltipContentAtom>
)

export const TooltipProvider = Primitive.Provider
export const TooltipTrigger = Primitive.Trigger
