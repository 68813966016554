import React, { createContext, useContext, useMemo, useReducer } from 'react'

import { GlobalDialogReducer } from './enums'
import { ContextPropsOptionsInterface } from '~/models/interfaces/ContextProps'

import { GlobalDialogContextType, initialState } from './initialState'
import { reducer } from './reducer'

import { Dialog, DialogActions, DialogHeader, DialogRoot } from '~/ds-components/molecules/Dialog'

import { getSpacing } from '~/assets/styles'

const GlobalDialogContext = createContext(
  {} as ContextPropsOptionsInterface<GlobalDialogContextType[], GlobalDialogContextType>,
)

interface GlobalDialogProps {
  children: React.ReactNode
}

export const GlobalDialogProvider = ({ children }: GlobalDialogProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleDialogVisibility = (dialog: GlobalDialogContextType) => {
    dispatch({
      type: GlobalDialogReducer.HIDE_DIALOG,
      payload: { dialogId: dialog.dialogId },
    })

    if (dialog.handleClose) dialog.handleClose()
  }

  const value = useMemo(() => ({ state, dispatch }), [state])

  return (
    <GlobalDialogContext.Provider value={value}>
      {children}

      {state.map((dialog) => {
        const { dialogId, header, content, actions, closable = true, ...rest } = dialog
        return (
          <DialogRoot
            open
            key={dialogId}
            onOpenChange={() => !closable ? undefined : handleDialogVisibility(dialog) }
            {...dialog.slotProps?.root}
          >
            <Dialog closable={closable} size="md" {...rest}>
              {header && <DialogHeader closable={closable} {...header} />}

              {content}

              {actions && <DialogActions padding={getSpacing(16, 32)} {...actions} />}
            </Dialog>
          </DialogRoot>
        )
      })}
    </GlobalDialogContext.Provider>
  )
}

export const useGlobalDialog = () => {
  const context = useContext(GlobalDialogContext)
  if (!context) throw new Error('useGlobalDialog must be used within a GlobalDialogContext')
  return context
}
