import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'

import MainTemplate from '../components/templates/Main'
import ScratchCardPage from '~/pages/ScratchCard/ScratchCardPage'

const routes = () => (
  <Router>
    <Switch>
      <Route exact path="/:slug/:contractNumber">
        <MainTemplate>
          <ScratchCardPage />
        </MainTemplate>
      </Route>
      <Route exact path="*">
        <MainTemplate>
          <ScratchCardPage />
        </MainTemplate>
      </Route>
    </Switch>
  </Router>
)

export default routes
