import React, { useCallback } from 'react'

import { useGlobalDialog } from '~/context/GlobalDialog'
import { GlobalDialogReducer } from '~/context/GlobalDialog/enums'
import { GlobalDialogContextType } from '~/context/GlobalDialog/initialState'

import { Button, ButtonProps, DialogDescription, DialogProps } from '~/ds-components'

type UseDialogInterface = Omit<DialogProps, 'children'> & GlobalDialogContextType

export type UseDialogProps = Omit<UseDialogInterface, 'dialogId'>

type ConfirmDialogProps = {
  cancelProps?: ButtonProps
  confirmProps?: ButtonProps
} & Pick<GlobalDialogContextType, 'content' | 'header' | 'size' | 'scroll' | 'slotProps'>

const useDialog = () => {
  const { state, dispatch } = useGlobalDialog()

  const exists = useCallback(
    (dialogId: GlobalDialogContextType['dialogId']) => state.some(({ dialogId: id }) => id === dialogId),
    [state],
  )

  const show = useCallback(
    (dialogProps: UseDialogInterface) => {
      dispatch({
        type: GlobalDialogReducer.SHOW_DIALOG,
        payload: dialogProps,
      })
    },
    [dispatch],
  )

  const update = useCallback(
    (dialogProps: UseDialogInterface) => {
      dispatch({
        type: GlobalDialogReducer.UPDATE_DIALOG,
        payload: dialogProps,
      })
    },
    [dispatch],
  )

  const hideDialog = useCallback(
    (dialogId: string) => {
      dispatch({
        type: GlobalDialogReducer.HIDE_DIALOG,
        payload: { dialogId },
      })
    },
    [dispatch],
  )

  const hideAllDialogs = useCallback(() => {
    dispatch({ type: GlobalDialogReducer.HIDE_ALL_DIALOGS })
  }, [dispatch])

  const confirm = useCallback(
    async (confirmDialogProps?: ConfirmDialogProps) => {
      const promise = new Promise<boolean>((resolve) => {
        const dialogId = 'confirm-exit'
        show({
          dialogId,
          actions: {
            children: (
              <>
                <Button
                  variant="outlined"
                  {...confirmDialogProps?.cancelProps}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    resolve(false)
                    hideDialog(dialogId)
                    if (confirmDialogProps?.cancelProps?.onClick) {
                      confirmDialogProps.cancelProps.onClick(event)
                    }
                  }}
                >
                  {confirmDialogProps?.cancelProps?.children || 'Cancelar'}
                </Button>

                <Button
                  {...confirmDialogProps?.confirmProps}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    resolve(true)
                    hideDialog(dialogId)
                    if (confirmDialogProps?.confirmProps?.onClick) {
                      confirmDialogProps.confirmProps.onClick(event)
                    }
                  }}
                >
                  {confirmDialogProps?.confirmProps?.children || 'Confirmar'}
                </Button>
              </>
            ),
          },
          size: 'sm',
          ...confirmDialogProps,
          header: {
            title: 'Confirmar ação',
            ...confirmDialogProps?.header,
          },
          content:
            typeof confirmDialogProps?.content === 'string' ? (
              <DialogDescription>{confirmDialogProps?.content}</DialogDescription>
            ) : (
              confirmDialogProps?.content || (
                <DialogDescription>Deseja realmente prosseguir com essa ação?</DialogDescription>
              )
            ),
          slotProps: {
            ...confirmDialogProps?.slotProps,
            content: {
              onCloseAutoFocus: () => resolve(false),
              ...confirmDialogProps?.slotProps?.content,
            },
          },
        })
      })

      const result = await promise
      return result
    },
    [hideDialog, show],
  )

  return {
    dialogs: state,
    exists,
    hideAllDialogs,
    hideDialog,
    show,
    update,
    confirm
  }
}

export type ShowDialogFunction = (props: UseDialogInterface) => void
export default useDialog
