import styled from 'styled-components'

export const ScratchCardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.2rem;
  border: 2px solid #ffffff;
  border-radius: 1.2rem;
  background-color: #1a337f66;
`
