import { ActionInterface } from '~/models/interfaces/Reducer'

import { GlobalDialogReducer } from './enums'
import { initialState, GlobalDialogContextType } from './initialState'

const idAlreadyRegistered = (
  state: GlobalDialogContextType[],
  dialogId: string,
): boolean => state.some((t) => t.dialogId === dialogId && !!dialogId)

const filterDialogs = (
  state: GlobalDialogContextType[],
  dialogId: string,
): GlobalDialogContextType[] => state.filter((t) => t.dialogId !== dialogId)

const findDialogIndex = (
  state: GlobalDialogContextType[],
  dialogId: string,
): number => state.findIndex((t) => t.dialogId === dialogId)

export const reducer = (
  state: GlobalDialogContextType[],
  action: ActionInterface<GlobalDialogContextType>,
) => {
  const { type, payload } = action
  let newState: GlobalDialogContextType[] = []

  switch (type) {
    case GlobalDialogReducer.SHOW_DIALOG:
      if (!payload?.dialogId) {
        throw new Error('O id do dialog é obrigatório')
      }
      if (idAlreadyRegistered(state, payload.dialogId)) {
        throw new Error(`Já existe um dialog registrado com o id "${payload.dialogId}"`)
      }
      return [...state, payload]

    case GlobalDialogReducer.UPDATE_DIALOG: {
      if (!payload?.dialogId) {
        throw new Error('O id do dialog é obrigatório')
      }

      const dialogToUpdateIndex = findDialogIndex(state, payload.dialogId)

      if (dialogToUpdateIndex === -1) {
        throw new Error(`Não existe um dialog registrado com o id "${payload.dialogId}"`)
      }

      newState = [...state]
      newState[dialogToUpdateIndex] = {
        ...newState[dialogToUpdateIndex],
        ...payload,
      }

      return newState
    }

    case GlobalDialogReducer.HIDE_DIALOG:
      if (!payload?.dialogId) {
        throw new Error('O id do dialog é obrigatório')
      }
      newState = filterDialogs(state, payload.dialogId)
      return newState

    case GlobalDialogReducer.HIDE_ALL_DIALOGS:
      return initialState

    default:
      return state
  }
}
