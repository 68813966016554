import * as DialogPrimitive from '@radix-ui/react-dialog'
import { PropsWithChildren } from 'react'
import styled, { keyframes, css } from 'styled-components'

import {
  DynamicProps,
  borderRadius,
  breakpoints,
  colors,
  dynamicProps,
  spacings,
  zIndex,
} from '~/assets/styles'

const { grey } = colors

const overlayShow = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const contentShow = keyframes`
  0% { transform: translate(-50%, -40%) scale(.96); }
  100% { transform: translate(-50%, -50%) scale(1); }
`
const contentScale = keyframes`
  0% { transform: scale(.96); }
  100% { transform: scale(1); }
`

export type DialogScroll = 'content' | 'overlay'

type DialogOverlayAtomProps = {
  scroll?: DialogScroll
}
export const DialogOverlayAtom = styled(DialogPrimitive.Overlay)<DialogOverlayAtomProps>`
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
  position: fixed;
  z-index: ${zIndex.modal};

  ${({ scroll }) => scroll === 'overlay'
    && css`
      display: grid;
      place-items: center;
      overflow-y: auto;
    `}

  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 200ms;
  }
`

export type DialogHeaderAtomProps = DynamicProps

export const DialogHeaderAtom = styled.header<PropsWithChildren<DialogHeaderAtomProps>>`
  border-bottom: 1px solid ${grey.lighter};

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${spacings[16]} ${spacings[32]};

  ${dynamicProps}
`

export type DialogContentAtomProps = {
  size?: DialogSize
  scroll?: DialogScroll
}

export const DialogContentAtom = styled(DialogPrimitive.Content)<DialogContentAtomProps>`
  background-color: ${grey.white};
  border-radius: ${borderRadius.large};
  max-width: ${({ size }) =>
    (size && size in contentSizes ? `${contentSizes[size as ContentSizes]}px` : size)};
  position: relative;
  width: 94vw;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentScale} 150ms ease-in-out;
  }

  ${({ scroll = 'content' }) => scroll === 'content'
    && css`
      overflow: 'hidden';
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (prefers-reduced-motion: no-preference) {
        animation: ${contentShow} 150ms ease-in-out;
      }
    `}

  &:focus {
    outline: none;
  }
`

const contentSizes = {
  xl: breakpoints.xl,
  lg: breakpoints.lg,
  md: breakpoints.md,
  sm: breakpoints.sm,
}

type ContentSizes = keyof typeof contentSizes

export type DialogSize = ContentSizes | (string & {})

type DialogBodyAtomProps = {
  hasHeader?: boolean
  hasActions?: boolean
  scroll?: DialogScroll
} & DynamicProps
export const DialogBodyAtom = styled.section<DialogBodyAtomProps>`
  padding: ${spacings[24]} ${spacings[32]};

  ${({ scroll = 'content', hasHeader, hasActions }) => scroll === 'content'
    && css`
      max-height: calc(85vh - ${getMaxHeightOffset({ hasHeader, hasActions })});
      overflow-y: auto;
    `}

  > :last-child {
    margin-bottom: 0 !important;
  }

  ${dynamicProps}
`

function getMaxHeightOffset({ hasHeader, hasActions }: DialogBodyAtomProps) {
  if (hasHeader && hasActions) return '17rem'
  if (hasHeader || hasActions) return '10rem'
  return spacings[16]
}

export const DialogCloseButtonAtom = styled(DialogPrimitive.Close)`
  all: unset;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  padding: ${spacings[4]};
  transition: background-color 0.2s ease-in-out, outline 0.1s;

  &:hover {
    background-color: ${colors.grey.lightest};
  }
  &:focus-visible {
    outline: 2px solid ${colors.primary.base};
  }
`

type FocusFallbackInputProps = {
  ref: React.RefObject<HTMLInputElement> | null
  'aria-hidden'?: boolean
  tabIndex?: number
  type?: string
  readOnly?: boolean
  inputMode?: string
}

export const FocusFallbackInput = styled.input<FocusFallbackInputProps>`
  all: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
`
FocusFallbackInput.defaultProps = {
  tabIndex: -1,
  'aria-hidden': true,
  type: 'text',
  readOnly: true,
  inputMode: 'none',
}
