import {
  MdArrowDropDown as ArrowDropDownIcon,
  MdCelebration as CelebrationIcon,
  MdClose as CloseIcon,
  MdContentCopy as ContentCopyIcon,
  MdDone as DoneIcon,
} from 'react-icons/md'

export * from './models'

export const filledIcons = {
  ArrowDropDown: {
    icon: ArrowDropDownIcon,
    ariaLabel: 'Abrir',
  },
  Celebration: {
    icon: CelebrationIcon,
    ariaLabel: 'Celebração',
  },
  Close: {
    icon: CloseIcon,
    ariaLabel: 'Fechar',
  },
  ContentCopy: {
    icon: ContentCopyIcon,
    ariaLabel: 'Copiar',
  },
  Done: {
    icon: DoneIcon,
    ariaLabel: 'Concluído',
  },
}

export type IconKeys = keyof typeof icons

export const icons = {
  ...filledIcons,
}

export {
  ArrowDropDownIcon,
  CelebrationIcon,
  CloseIcon,
  ContentCopyIcon,
  DoneIcon,
}
