import { Flex, FlexProps } from '../Flex'

import { icons, IconProps as BaseIconProps, IconKeys } from '~/assets/ds-icons'
import { BorderRadiusValue, getSxProps, getUnaryColor } from '~/assets/styles'

export type IconProps = {
  name: IconKeys
  backgroundColor?: string
  /**
   * The icon border radius.
   * @default '50%'
   */
  borderRadius?: BorderRadiusValue
  border?: string
  /**
   * The icon ratio when using `backgroundColor`.
   * @default 0.6
   */
  ratio?: number
  slotProps?: { root?: FlexProps }
} & BaseIconProps

const handleSize = (size?: string | number) => {
  if (typeof size === 'number') return `${size}px`
  return size
}

export const Icon = (props: IconProps) => {
  const {
    name,
    color,
    backgroundColor,
    border,
    borderRadius = '50%',
    ratio = backgroundColor ? 0.6 : 1,
    slotProps,
    ...rest
  } = props

  const { icon: IconComponent, ariaLabel } = icons[name]
  const iconSize = `${ratio * 100}%`

  const iconComponent = (
    <IconComponent
      aria-label={ariaLabel}
      color={getUnaryColor(color)}
      data-testid="icon"
      {...rest}
    />
  )

  return backgroundColor ? (
    <Flex
      as="span"
      alignItems="center"
      justifyContent="center"
      backgroundColor={backgroundColor}
      border={border}
      borderRadius={borderRadius}
      width={handleSize(rest.width) || handleSize(rest.size) || 'fit-content'}
      height={handleSize(rest.height) || handleSize(rest.size) || 'auto'}
      {...slotProps?.root}
      sx={(theme) => ({
        svg: { width: iconSize, height: iconSize },
        ...getSxProps(slotProps?.root?.sx, theme),
      })}
      data-testid="icon-wrapper"
    >
      {iconComponent}
    </Flex>
  ) : (
    iconComponent
  )
}
