import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { typography, DynamicProps, dynamicProps, TypographyKeys } from '~/assets/styles'

export type TypographyAtomProps = {
  variant?: TypographyKeys
  bold?: boolean
  whiteSpace?: React.CSSProperties['whiteSpace']
} & DynamicProps

export const TypographyAtom = styled.p<TypographyAtomProps>`
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  line-height: 120%;
  ${({ variant }) => variants[variant || 'medium']}
  white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
  ${dynamicProps}
`

const variants: Record<TypographyKeys, FlattenSimpleInterpolation> = {
  '8xLarge': css`
    font-size: ${typography['8xLarge']};
  `,
  '7xLarge': css`
    font-size: ${typography['7xLarge']};
  `,
  '6xLarge': css`
    font-size: ${typography['6xLarge']};
  `,
  '5xLarge': css`
    font-size: ${typography['5xLarge']};
  `,
  '4xLarge': css`
    font-size: ${typography['4xLarge']};
  `,
  '3xLarge': css`
    font-size: ${typography['3xLarge']};
  `,
  '2xLarge': css`
    font-size: ${typography['2xLarge']};
  `,
  xLarge: css`
    font-size: ${typography.xLarge};
  `,
  large: css`
    font-size: ${typography.large};
  `,
  medium: css`
    font-size: ${typography.medium};
  `,
  small: css`
    font-size: ${typography.small};
  `,
  xSmall: css`
    font-size: ${typography.xSmall};
  `,
  '2xSmall': css`
    font-size: ${typography['2xSmall']};
  `,
}
