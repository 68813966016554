import React, { forwardRef } from 'react'
import { PolymorphicComponentPropWithRef, PolymorphicRef, TypographyKeys } from '~/assets/styles'
import { TypographyAtom, TypographyAtomProps } from './styles'

export type TypographyProps<C extends React.ElementType = 'p'> = PolymorphicComponentPropWithRef<
  C,
  TypographyAtomProps
>

type TypographyComponent = {
  <C extends React.ElementType = 'p'>(props: TypographyProps<C>): React.ReactElement | null
  displayName?: string
}

export const Typography: TypographyComponent = forwardRef(
  <C extends React.ElementType = 'p'>(props: TypographyProps<C>, ref?: PolymorphicRef<C>) => {
    const { as, variant, ...rest } = props

    const Component = as || variantMapping[variant || 'medium']

    return (
      <TypographyAtom
        as={Component}
        ref={ref}
        variant={variant}
        data-testid="typography"
        {...rest}
      />
    )
  },
)

Typography.displayName = 'Typography'

const variantMapping: Record<TypographyKeys, React.ElementType> = {
  '8xLarge': 'h1',
  '7xLarge': 'h2',
  '6xLarge': 'h2',
  '5xLarge': 'h2',
  '4xLarge': 'h3',
  '3xLarge': 'h3',
  '2xLarge': 'h4',
  xLarge: 'p',
  large: 'p',
  medium: 'p',
  small: 'p',
  xSmall: 'p',
  '2xSmall': 'span',
} as const
