import { PropsWithChildren } from 'react'
import styled, { keyframes } from 'styled-components'
import { SxProps, sxProps } from '~/assets/styles'

export type LoaderWrapperProps = {
  size?: number
} & SxProps

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const LoaderWrapper = styled.span<PropsWithChildren<LoaderWrapperProps>>`
  display: inline-flex;

  & > svg {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    animation: ${rotate} 1s linear infinite;
  }

  ${sxProps}
`
