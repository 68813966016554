import React, { useState } from 'react'

import { Button, ButtonProps } from '../Button'
import { Flex } from '../Flex'
import { Icon, IconProps } from '../Icon'
import { IconButton, IconButtonProps } from '../IconButton'
import { Typography } from '../Typography'
import { Popover, PopoverTrigger, PopoverContent } from '~/ds-components/molecules/Popover'

import { getColor } from '../Button/styles'
import { getSpacing } from '~/assets/styles'

export type CopyButtonProps = DefaultCopyButtonProps | IconCopyButtonProps

export type DefaultCopyButtonProps = {
  buttonType?: 'default'
  value?: string | GroupValue[]
} & CopyButtonBaseProps &
  Omit<ButtonProps, 'value'>

export type IconCopyButtonProps = {
  value?: string
  buttonType: 'icon'
} & CopyButtonBaseProps &
  Omit<IconButtonProps, 'value'>

type CopyButtonBaseProps = {
  copiedText?: string
  copiedColor?: ButtonProps['color']
  iconProps?: IconProps
  slotProps?: {
    icon?: IconProps
  }
}

type GroupValue = {
  value: string
  label: string
}

type CopyButtonComponent = React.ForwardRefExoticComponent<CopyButtonProps>

export const CopyButton = React.forwardRef<HTMLButtonElement, CopyButtonProps>((props, ref) => {
  const {
    buttonType = 'default',
    children,
    color,
    onClick,
    value,
    iconProps,
    copiedText = 'Copiado',
    copiedColor = 'transactional',
    variant,
    slotProps,
    ...rest
  } = props

  const [copied, setCopied] = useState(false)
  const [copiedMenu, setCopiedMenu] = useState<boolean[]>(
    Array.isArray(value) ? new Array(value.length).fill(false) : [],
  )

  if (Array.isArray(value) && !copiedMenu.length) {
    setCopiedMenu(new Array(value.length).fill(false))
  }

  const icon = (
    <Icon name={!copied ? 'ContentCopy' : 'Done'} size={16} {...iconProps} {...slotProps?.icon} />
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    copyValue()
    if (onClick) onClick(event)
  }

  function copyValue() {
    let valueToCopy = ''

    if (value && ['string', 'number'].includes(typeof value)) valueToCopy = value.toString()
    else if (children && ['string', 'number'].includes(typeof children)) {
      valueToCopy = children.toString()
    }

    navigator.clipboard.writeText(valueToCopy)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  function copyValueMenu(localValue: string, menuIndex: number) {
    navigator.clipboard.writeText(localValue)

    toggleCopied(true)

    setTimeout(() => toggleCopied(false), 2_000)

    function toggleCopied(isMenuItemCopied: boolean) {
      setCopiedMenu((prevValue) =>
        prevValue.map((isCopied, index) => (index === menuIndex ? isMenuItemCopied : isCopied)),
      )
    }
  }

  if (buttonType === 'icon') {
    return (
      <IconButton
        ref={ref}
        color={!copied ? color : copiedColor}
        variant={variant}
        onClick={handleClick}
        data-testid="copy-button"
        {...rest}
      >
        {icon}
      </IconButton>
    )
  }

  if (Array.isArray(value)) {
    return (
      <Popover>
        <PopoverTrigger>
          <Button
            ref={ref}
            color={!copied ? color : copiedColor}
            endIcon={
              <Icon
                name={copied ? 'Done' : 'ArrowDropDown'}
                size={copied ? 20 : 24}
                {...iconProps}
                {...slotProps?.icon}
              />
            }
            variant={variant}
            sx={{
              '&:not(:disabled):hover': {
                color: variant === 'link' && copied ? getColor(copiedColor)[600] : undefined,
              },
            }}
            {...rest}
          >
            {!copied ? children : copiedText}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          showArrow={false}
          showClose={false}
          sx={({ borderRadius }) => ({
            borderRadius: borderRadius.small,
            padding: getSpacing(8, 16),
          })}
        >
          <Flex as="ul" flexDirection="column" gap={8}>
            {value.map((item, index) => (
              <Flex
                as="li"
                key={item.value}
                alignItems="center"
                gap={8}
                justifyContent="space-between"
              >
                <Typography color="grey.base" variant="small">
                  {item.label}
                </Typography>

                <IconButton
                  color={!copiedMenu[index] ? color : copiedColor}
                  onClick={() => copyValueMenu(item.value, index)}
                  size="small"
                  variant="link"
                >
                  <Icon name={!copiedMenu[index] ? 'ContentCopy' : 'Done'} />
                </IconButton>
              </Flex>
            ))}
          </Flex>
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <Button
      ref={ref}
      color={!copied ? color : copiedColor}
      variant={variant}
      onClick={handleClick}
      sx={{
        '&:not(:disabled):hover': {
          color: variant === 'link' && copied ? getColor(copiedColor)[600] : undefined,
        },
      }}
      data-testid="copy-button"
      {...rest}
    >
      {!copied ? children : copiedText}

      <span>{icon}</span>
    </Button>
  )
}) as CopyButtonComponent

CopyButton.displayName = 'CopyButton'
