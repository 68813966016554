import { HTMLAttributes } from 'react'
import styled from 'styled-components'

export type ScratchCardContainerProps = {
  backgroundImage: string
} & HTMLAttributes<HTMLDivElement>

export const ScratchCardContainer = styled.div<ScratchCardContainerProps>`
  background: url(${({ backgroundImage }) => backgroundImage}) no-repeat center / cover;
  min-height: 100vh;
  max-width: 100vw;
  padding-block: 1.6rem;
  overflow: hidden;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  canvas {
    box-sizing: border-box;
    border-radius: 1.2rem;

    &:not([style*='opacity: 0;']) {
      cursor: pointer;
    }
  }
`
