import React, { forwardRef } from 'react'
import { PolymorphicComponentPropWithRef, PolymorphicRef, DynamicProps } from '~/assets/styles'
import { BoxAtom } from './styles'

export type BoxProps<ElementType extends React.ElementType = 'div'> =
  PolymorphicComponentPropWithRef<ElementType, DynamicProps>

type BoxComponent = {
  <ElementType extends React.ElementType = 'div'>(
    props: BoxProps<ElementType>,
  ): React.ReactElement | null
  displayName?: string
}

export const Box: BoxComponent = forwardRef(
  <ElementType extends React.ElementType = 'div'>(
    props: BoxProps<ElementType>,
    ref?: PolymorphicRef<ElementType>,
  ) => {
    const { as, ...rest } = props
    return <BoxAtom as={as || 'div'} ref={ref} data-testid="box" {...rest} />
  },
)

Box.displayName = 'Box'
