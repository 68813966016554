import styled, { css } from 'styled-components'
import { SxProps, borderRadius, colors, spacings, sxProps, typography } from '~/assets/styles'

const { blue, error, info, gray, orange, success, warning } = colors

export type ButtonAtomProps = {
  /**
   * Define the color of the button
   * @default primary
   */
  color?: ButtonColor
  /**
   * If `true`, the button will take up the full width of its container
   */
  fullWidth?: boolean
  /**
   * If `true`, a loading spinner will be displayed
   */
  loading?: boolean
  /**
   * Define the size of the button
   * @default medium
   */
  size?: ButtonSize
  /**
   * Define the variant of the button
   * @default contained
   */
  variant?: ButtonVariant
  /**
   * Define the version of the button
   * @default v1
   */
  version?: ButtonVersion
  dark?: boolean
} & SxProps

export type ButtonColor = keyof typeof buttonColors
export type ButtonSize = 'medium' | 'mobile' | 'small' | 'smaller' | 'large'
export type ButtonVariant = 'contained' | 'outlined' | 'ghost' | 'link'
export type ButtonVersion = 'v1' | 'v2'

const buttonColors = {
  primary: blue,
  destructive: error,
  info,
  secondary: orange,
  transactional: success,
  warning,
  neutral: gray,
} as const

export const buttonColorsKeys = Object.keys(buttonColors)
export const buttonColorsKeysString = buttonColorsKeys.join(' | ')

export const buttonSizesKeys: ButtonSize[] = ['medium', 'small', 'smaller', 'large']
export const buttonSizesString = buttonSizesKeys.join(' | ')

export const buttonVariantsKeys: ButtonVariant[] = ['contained', 'outlined', 'ghost', 'link']
export const buttonVariantsString = buttonVariantsKeys.join(' | ')

export const buttonVersionsKeys: ButtonVersion[] = ['v1', 'v2']
export const buttonVersionsString = buttonVersionsKeys.join(' | ')

export const ButtonAtom = styled.button<ButtonAtomProps>`
  border: 1px solid transparent;
  border-radius: ${borderRadius.small};
  font-weight: 600;
  font-size: ${typography.small};
  line-height: ${({ version }) => (version === 'v2' ? '120%' : '150%')};
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out, outline 0.1s;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  align-items: center;
  display: inline-flex;
  gap: ${({ version }) => (version === 'v2' ? spacings[4] : spacings[8])};
  justify-content: center;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    ${(props) => getDisabledStyles(props)}
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  ${({ size, variant, version }) => getSizeStyles(version, variant)[size || 'medium']}
  ${(props) => getVariantStyles(props)}
  ${({ loading }) => getLoadingStyles(loading)}

  & > span {
    display: inherit;
  }

  & svg {
    transition: transform 0.2s ease-in-out;
  }

  ${sxProps}
`

const getSizeStyles = (version: ButtonAtomProps['version'], variant: ButtonAtomProps['variant']) =>
  version === 'v2'
    ? {
        mobile: css`
          font-size: ${typography.xLarge};
          padding: ${spacings[12]};
        `,
        medium: css`
          padding: ${spacings[8]} ${spacings[12]};
          font-size: ${typography.small};
        `,
        small: css`
          font-size: ${typography.xSmall};
          padding: ${spacings[6]} ${spacings[12]};
        `,
        large: css`
          font-size: ${typography.xLarge};
          padding: ${spacings[12]};
        `,
      }
    : {
        smaller: css`
          font-size: ${typography.small};
          line-height: 120%;
          padding: ${spacings[4]} ${spacings[12]};
        `,
        medium: css`
          padding: ${spacings[8]} ${spacings[16]};
        `,
        small: css`
          font-size: ${variant === 'link' ? typography.small : typography.medium};
          font-weight: ${variant === 'link' ? 400 : 600};
          line-height: ${variant === 'link' ? '140%' : '150%'};
          padding: ${spacings[4]} ${spacings[16]};
        `,
        large: css`
          font-size: ${typography.xLarge};
          padding: ${spacings[12]} ${spacings[32]};
        `,
      }

const getLoadingStyles = (loading: ButtonAtomProps['loading']) => {
  if (!loading) return ''

  return css`
    & > span:last-of-type {
      opacity: 0;
    }
  `
}

function getDisabledStyles(props: ButtonAtomProps) {
  const { loading, variant } = props

  const color = getColor(props.color)

  const disabledStyles = {
    contained: css`
      background-color: ${color[100]};
      color: ${colors.white};
    `,
    outlined: css`
      border-color: ${color[100]};
      color: ${color[100]};
    `,
    ghost: css`
      color: ${color[100]};
    `,
    link: css`
      color: ${color[100]};
    `,
  }

  if (loading) return ''
  return disabledStyles[variant || 'contained']
}

const getVariantStyles = (props: ButtonAtomProps) => {
  const colorKey = props.color
  const color = getColor(colorKey)
  const color500or600 = colorKey === 'primary' ? color[500] : color[600]

  const variants = {
    contained: css`
      background-color: ${color500or600};
      color: ${colors.white};

      &:not(:disabled) {
        &:hover {
          background-color: ${color[700]};
        }
        &:active {
          background-color: ${color[300]};
        }
        &:focus-visible {
          outline: 4px solid ${color[200]};
          outline-offset: 2px;
        }
      }
    `,
    outlined: css`
      border-color: ${colors.gray[200]};
      background-color: transparent;
      color: ${color500or600};

      &:not(:disabled) {
        &:hover {
          background-color: ${color[50]};
          color: ${color[700]};
        }
        &:active {
          background-color: ${color[50]};
          color: ${color500or600};
        }

        &:focus-visible {
          border-color: ${color[200]};
          outline: 3px solid ${color[200]};
        }
      }
    `,
    ghost: css`
      color: ${color500or600};

      &:not(:disabled) {
        &:hover {
          background-color: ${color[50]};
          color: ${color[700]};
        }
        &:active {
          background-color: ${color[50]};
          color: ${color500or600};
        }
        &:focus-visible {
          border-color: ${color[200]};
          outline: 3px solid ${color[200]};
        }
      }
    `,
    link: css`
      border-radius: unset;
      color: ${color500or600};
      padding: ${spacings[4]};

      &:not(:disabled) {
        &:hover {
          color: ${color[700]};
          border-bottom-color: ${color[700]};
        }
        &:active {
          color: ${color[300]};
          border-bottom-color: ${color[300]};
        }
        &:focus-visible {
          border-radius: ${borderRadius.small};
          border-color: ${color[200]};
          outline: 1px solid ${color[200]};
        }
      }
    `,
  }

  return variants[props.variant || 'contained']
}

export const getColor = (colorKey: ButtonAtomProps['color']) => buttonColors[colorKey || 'primary']
