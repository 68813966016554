import styled, { keyframes, CSSProperties } from 'styled-components'
import * as Popover from '@radix-ui/react-popover'
import { borderRadius, colors, spacings, shadows, sxProps, SxProps } from '~/assets/styles'

const { background, grey, primary } = colors

const slideUpAndFade = keyframes`
  0% { opacity: 0; transform: translateY(2px) }
  100% { opacity: 1; transform: translateY(0) }
`

const slideRightAndFade = keyframes`
  0% { opacity: 0; transform: translateX(-2px) }
  100% { opacity: 1; transform: translateX(0) }
`

const slideDownAndFade = keyframes`
  0% { opacity: 0; transform: translateY(-2px) }
  100% { opacity: 1; transform: translateY(0) }
`

const slideLeftAndFade = keyframes`
  0% { opacity: 0; transform: translateX(2px) }
  100% { opacity: 1; transform: translateX(0) }
`

export type PopoverRootAtomProps = Popover.PopoverProps

export type PopoverContentAtomProps = {
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  maxHeight?: CSSProperties['maxHeight']
} & Popover.PopoverContentProps & SxProps

export const PopoverContentAtom = styled(Popover.Content)<PopoverContentAtomProps>`
  background-color: ${background.white};
  border-radius: ${borderRadius.large};
  box-shadow: ${shadows[3]};
  max-width: ${({ width }) => width || spacings[256]};
  min-height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight || 'initial'};
  padding: ${spacings[24]};

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &[data-state='open'] {
      &[data-side='top'] {
        animation-name: ${slideDownAndFade};
      }
      &[data-side='right'] {
        animation-name: ${slideLeftAndFade};
      }
      &[data-side='bottom'] {
        animation-name: ${slideUpAndFade};
      }
      &[data-side='left'] {
        animation-name: ${slideRightAndFade};
      }
    }
  }

  &:focus-visible {
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
      0 0 0 2px ${grey.blueish};
  }

  ${sxProps}
`

export type PopoverCloseAtomProps = Popover.PopoverCloseProps

export const PopoverCloseAtom = styled(Popover.Close)`
  all: unset;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  padding: ${spacings[4]};
  transition: background-color 0.2s ease-in-out, outline 0.1s;

  position: absolute;
  right: 3px;
  top: 3px;

  &:hover {
    background-color: ${grey.lightest};
  }
  &:focus-visible {
    outline: 2px solid ${primary.base};
  }
`
PopoverCloseAtom.defaultProps = {
  type: 'button',
}

export type PopoverTriggerAtomProps = Popover.PopoverTriggerProps

export const PopoverTriggerAtom = styled(Popover.Trigger)`
  cursor: pointer;
  height: fit-content;

  &:focus-visible {
    box-shadow: 0 0 0 2px ${grey.blueish};
  }
`
PopoverTriggerAtom.defaultProps = {
  type: 'button',
}

export type PopoverArrowAtomProps = Popover.PopoverArrowProps

export const PopoverArrowAtom = styled(Popover.Arrow)`
  fill: ${grey.white};
`
