import React, { forwardRef } from 'react'
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '~/assets/styles'
import { ButtonAtom, ButtonAtomProps } from './styles'
import { Loader } from '../Loader'

export type ButtonProps<ComponentElementType extends React.ElementType = 'button'> =
  PolymorphicComponentPropWithRef<
    ComponentElementType,
    { endIcon?: JSX.Element; startIcon?: JSX.Element } & ButtonAtomProps
  >

type ButtonComponent = {
  <ComponentElementType extends React.ElementType = 'button'>(
    props: ButtonProps<ComponentElementType>,
  ): React.ReactElement | null
  displayName?: string
}

export const Button: ButtonComponent = forwardRef(
  <C extends React.ElementType = 'button'>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => {
    const {
      as,
      children,
      color = 'primary',
      endIcon,
      loading,
      startIcon,
      variant = 'contained',
      ...rest
    } = props

    return (
      <ButtonAtom
        as={as || 'button'}
        ref={ref}
        type="button"
        color={color}
        loading={loading}
        variant={variant}
        disabled={loading}
        data-testid="button"
        {...rest}
      >
        {!loading ? (
          <>
            {startIcon && <span>{startIcon}</span>}
            {children}
            {endIcon && <span>{endIcon}</span>}
          </>
        ) : (
          <>
            <Loader
              size={['small', 'medium'].includes(rest.size || '') ? 'xs' : 'sm'}
              variant={variant !== 'contained' ? 'primary' : 'light'}
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
            <span>{children}</span>
          </>
        )}
      </ButtonAtom>
    )
  },
)

Button.displayName = 'Button'
