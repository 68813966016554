import React, {
  ButtonHTMLAttributes,
  Children,
  HTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
  useRef,
} from 'react'
import * as Primitive from '@radix-ui/react-dialog'

import { BoxProps, Flex, FlexProps, Typography, TypographyProps } from '~/ds-components/atoms'
import { Title, TitleProps } from '../Title'

import { CloseIcon } from '~/assets/ds-icons'
import {
  DialogSize,
  DialogBodyAtom,
  DialogCloseButtonAtom,
  DialogContentAtom,
  DialogHeaderAtom,
  DialogHeaderAtomProps,
  DialogOverlayAtom,
  DialogScroll,
  FocusFallbackInput,
} from './styles'

export interface DialogProps {
  children?: React.ReactNode
  /** Determines if the dialog is closable. If true, displays a close button in the header
   * @default true
   */
  closable?: boolean
  /** Determines if the overlay is closable
   * @default true
   */
  closableOverlay?: boolean
  /** Controls the pointer-events of the body when the dialog is open
   * @default 'none'
   */
  size?: DialogSize
  /** Determines the scroll behavior of the dialog
   * @default 'content'
   */
  scroll?: DialogScroll
  slotProps?: {
    content?: Primitive.DialogContentProps
    portal?: Primitive.DialogPortalProps
    overlay?: Primitive.DialogOverlayProps
    body?: BoxProps
  }
}

type Elements = {
  header?: ReactElement
  actions?: ReactElement
  rest: ReactNode[]
}

export const Dialog = (props: DialogProps) => {
  const { closableOverlay = true, children, slotProps, scroll = 'content', size = 'md' } = props

  const contentRef = useRef<HTMLDivElement>(null)
  const fallbackFocusInputRef = useRef<HTMLInputElement>(null)

  const elements: Elements = {
    header: undefined,
    actions: undefined,
    rest: [],
  }

  const getElements = (child: ReactNode) => {
    if (isValidElement(child)) {
      if (child.type === DialogHeader) elements.header = child
      else if (child.type === DialogActions) elements.actions = child
      else if (child.type === React.Fragment) {
        Children.forEach(child.props.children, getElements)
      } else elements.rest.push(child)
    } else elements.rest.push(child)
  }

  Children.forEach(children, getElements)

  const dialogContainer = document.getElementById('root')

  return (
    <Primitive.Portal container={dialogContainer} {...slotProps?.portal}>
      <DialogOverlayAtom scroll={scroll} data-testid="dialog-overlay" {...slotProps?.overlay}>
        <DialogContentAtom
          {...slotProps?.content}
          ref={contentRef}
          onOpenAutoFocus={(event) => {
            event.preventDefault()

            const element = contentRef.current?.querySelector('.should-focus') as HTMLElement
            if (element) {
              element.focus()
              return
            }

            if (fallbackFocusInputRef.current) fallbackFocusInputRef.current.focus()
          }}
          onPointerDownOutside={(event) => {
            if (!contentRef.current) return
            const contentRect = contentRef.current.getBoundingClientRect()
            const clickedInside =
              event.detail.originalEvent.clientX > contentRect.left &&
              event.detail.originalEvent.clientX < contentRect.left + contentRect.width &&
              event.detail.originalEvent.clientY > contentRect.top &&
              event.detail.originalEvent.clientY < contentRect.top + contentRect.height
            if (clickedInside || !closableOverlay) event.preventDefault()
            if (slotProps?.content?.onPointerDownOutside) {
              slotProps.content.onPointerDownOutside(event)
            }
          }}
          size={size}
          scroll={scroll}
          data-testid="dialog-content"
        >
          {elements.header}

          <DialogBodyAtom
            hasHeader={!!elements.header}
            hasActions={!!elements.actions}
            scroll={scroll}
            data-testid="dialog-body"
            {...slotProps?.body}
          >
            <FocusFallbackInput
              ref={fallbackFocusInputRef}
              data-testid="dialog-focus-fallback-input"
            />
            {elements.rest}
          </DialogBodyAtom>

          {elements.actions}
        </DialogContentAtom>
      </DialogOverlayAtom>
    </Primitive.Portal>
  )
}

export type DialogHeaderProps = {
  title?: TitleProps['children']
  icon?: TitleProps['icon']
  closable?: boolean
  slotProps?: {
    title?: TitleProps
    closeButton?: ButtonHTMLAttributes<HTMLButtonElement>
  }
} & DialogHeaderAtomProps &
  HTMLAttributes<HTMLDivElement>

export const DialogHeader = (props: DialogHeaderProps) => {
  const { title, icon, children, closable = true, slotProps, ...rest } = props
  return (
    <DialogHeaderAtom data-testid="dialog-header" {...rest}>
      <Primitive.Title asChild>
        <Title as="h2" icon={icon} data-testid="dialog-header-title" {...slotProps?.title}>
          {title}
        </Title>
      </Primitive.Title>
      {children}
      {closable && (
        <DialogCloseButtonAtom
          aria-label="Fechar"
          data-testid="dialog-header-close-button"
          {...slotProps?.closeButton}
        >
          <CloseIcon size={24} />
        </DialogCloseButtonAtom>
      )}
    </DialogHeaderAtom>
  )
}

export type DialogDescriptionProps = TypographyProps

export const DialogDescription = (props: DialogDescriptionProps) => (
  <Primitive.Description asChild>
    <Typography data-testid="dialog-description" {...props} />
  </Primitive.Description>
)

export type DialogActionsProps = FlexProps<'footer'>

export const DialogActions = (props: DialogActionsProps) => (
  <Flex
    as="footer"
    alignItems="center"
    justifyContent="flex-end"
    gap={8}
    paddingTop={16}
    data-testid="dialog-actions"
    {...props}
  />
)

export type DialogRootProps = Primitive.DialogProps
export const DialogRoot = Primitive.Root

export type DialogTriggerProps = Primitive.DialogTriggerProps
export const DialogTrigger = Primitive.Trigger

export type DialogCloseProps = Primitive.DialogCloseProps
export const DialogClose = Primitive.Close
