import React, { forwardRef } from 'react'
import { PolymorphicComponentPropWithRef, PolymorphicRef, DynamicProps } from '~/assets/styles'
import { FlexAtom } from './styles'

export type FlexProps<C extends React.ElementType = 'div'> = PolymorphicComponentPropWithRef<
  C,
  DynamicProps
>

type FlexComponent = {
  <C extends React.ElementType = 'div'>(props: FlexProps<C>): React.ReactElement | null
  displayName?: string
}

export const Flex: FlexComponent = forwardRef(
  <C extends React.ElementType = 'div'>(props: FlexProps<C>, ref?: PolymorphicRef<C>) => {
    const { as, ...rest } = props
    return <FlexAtom as={as || 'div'} ref={ref} data-testid="flex" {...rest} />
  },
)

Flex.displayName = 'Flex'
