import { default as cloverLeaf, ReactComponent as CloverLeaf } from './clover-leaf.svg'
import {
  default as defaultBackground,
  ReactComponent as DefaultBackground,
} from './default-background.svg'
import { default as raspouGanhou, ReactComponent as RaspouGanhou } from './raspou-ganhou.svg'
import { default as stars, ReactComponent as Stars } from './stars.svg'

export const SVG = {
  CloverLeaf,
  DefaultBackground,
  RaspouGanhou,
  Stars,
}

export const svgPaths = {
  cloverLeaf,
  defaultBackground,
  raspouGanhou,
  stars,
}
