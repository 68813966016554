import { cloneElement, ElementType, ReactElement, forwardRef } from 'react'
import {
  CopyButton,
  Flex,
  FlexProps,
  IconCopyButtonProps,
  Typography,
  TypographyProps,
} from '~/ds-components/atoms'

import { IconProps } from '~/assets/ds-icons'
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
  Theme,
  getSxProps,
  getUnaryColor,
} from '~/assets/styles'

export type TitleProps<TitleElementType extends ElementType = 'h3'> =
  PolymorphicComponentPropWithRef<
    TitleElementType,
    {
      copyable?: boolean
      icon?: ReactElement<IconProps>
      ellipsis?: boolean
      slots?: {
        root?: ElementType
        title?: ElementType
      }
      slotProps?: {
        root?: FlexProps
        title?: TypographyProps<TitleElementType>
        copyButton?: IconCopyButtonProps
      }
    } & TypographyProps<TitleElementType>
  >

type TitleComponent = {
  <TitleElementType extends ElementType = 'h3'>(
    props: TitleProps<TitleElementType>,
  ): ReactElement | null
  displayName?: string
}

export const Title: TitleComponent = forwardRef(
  <TitleElementType extends ElementType = 'h3'>(
    props: TitleProps<TitleElementType>,
    ref?: PolymorphicRef<TitleElementType>,
  ) => {
    const { as, children, color, copyable, ellipsis, icon, slots, slotProps, sx, ...rest } = props

    const titleComponent = (
      <Typography
        ref={ref}
        as={as || slots?.title || 'h3'}
        variant="2xLarge"
        color={color ?? 'grey.darker'}
        bold
        data-testid="title"
        {...slotProps?.title}
        {...rest}
        sx={(theme) => ({
          ...(ellipsis && { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }),
          ...getSxProps(sx, theme),
          ...getSxProps(slotProps?.title?.sx, theme),
        })}
      >
        {children}
      </Typography>
    )

    if (icon || copyable) {
      const clonedIcon =
        icon &&
        cloneElement(icon, {
          color: icon.props.color || getUnaryColor(props.color || 'grey.darker'),
          size: icon.props.size || 24,
        })

      return (
        <Flex
          as={slots?.root || 'div'}
          alignItems="center"
          gap={4}
          data-testid="title-root"
          {...slotProps?.root}
        >
          {clonedIcon && <Flex as="span">{clonedIcon}</Flex>}
          {titleComponent}
          {copyable && (
            <CopyButton
              value={typeof children === 'string' ? children : undefined}
              buttonType="icon"
              size="small"
              tooltip="Copiar"
              variant="link"
              {...slotProps?.copyButton}
              sx={(theme: Theme) => ({
                marginBlock: '-1rem',
                ...getSxProps(slotProps?.copyButton?.sx, theme),
              })}
            />
          )}
        </Flex>
      )
    }

    return titleComponent
  },
)

Title.displayName = 'Title'
