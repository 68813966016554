import React, { forwardRef, ElementType } from 'react'

import { Tooltip, TooltipProps } from '../Tooltip'

import { PolymorphicComponentPropWithRef, PolymorphicRef } from '~/assets/styles'
import { IconButtonAtom, IconButtonAtomProps } from './styles'

export type IconButtonProps<ComponentElementType extends ElementType = 'button'> =
  PolymorphicComponentPropWithRef<
    ComponentElementType,
    IconButtonAtomProps & {
      tooltip?: React.ReactNode
      slotProps?: {
        tooltip?: TooltipProps
      }
    }
  >

type IconButtonComponent = {
  <ComponentElementType extends ElementType = 'button'>(
    props: IconButtonProps<ComponentElementType>,
  ): React.ReactElement | null
  displayName?: string
}

export const IconButton: IconButtonComponent = forwardRef(
  <ComponentElementType extends ElementType = 'button'>(
    props: IconButtonProps<ComponentElementType>,
    ref?: PolymorphicRef<ComponentElementType>,
  ) => {
    const { as, tooltip, slotProps, ...rest } = props

    const component = (
      <IconButtonAtom
        ref={ref}
        as={as || 'button'}
        type="button"
        data-testid="icon-button"
        {...rest}
      />
    )

    if (tooltip) {
      return (
        <Tooltip
          content={tooltip}
          open={rest.disabled ? false : undefined}
          noPortal
          {...slotProps?.tooltip}
        >
          {component}
        </Tooltip>
      )
    }

    return component
  },
)

IconButton.displayName = 'IconButton'
