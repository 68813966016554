import { ReactNode } from 'react'
import {
  DialogProps,
  DialogRootProps,
  DialogHeaderProps,
  DialogActionsProps,
} from '~/ds-components/molecules/Dialog'

export type GlobalDialogContextType = {
  dialogId: string
  header?: DialogHeaderProps
  content?: ReactNode
  actions?: DialogActionsProps
  handleClose?: () => void
  slotProps?: {
    root?: DialogRootProps
  } & DialogProps['slotProps']
} & Omit<DialogProps, 'children'>

export const initialState: GlobalDialogContextType[] = []
