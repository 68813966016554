import { CSSObject, CSSProperties, css } from 'styled-components'
import { getUnaryBorderRadius, BorderRadiusValue } from './borderRadius'
import { getUnaryColor, Color } from './colors'
import { getUnarySpacing, Spacings } from './spacing'
import { responsive, ResponsiveStyleValue } from './responsive'
import { Theme } from './theme'

export type Spacing = keyof Spacings | string

export type MarginProps = {
  margin?: ResponsiveStyleValue<Spacing>
  marginBottom?: ResponsiveStyleValue<Spacing>
  marginBlock?: ResponsiveStyleValue<Spacing>
  marginInline?: ResponsiveStyleValue<Spacing>
  marginLeft?: ResponsiveStyleValue<Spacing>
  marginRight?: ResponsiveStyleValue<Spacing>
  marginTop?: ResponsiveStyleValue<Spacing>
}

export const marginProps = css<MarginProps>`
  ${({
    margin, marginBottom, marginBlock, marginInline, marginLeft, marginRight, marginTop,
  }) => responsive([
    {
      property: 'margin',
      values: margin,
      valueParser: getUnarySpacing,
    },
    {
      property: 'margin-bottom',
      values: marginBottom,
      valueParser: getUnarySpacing,
    },
    {
      property: 'margin-block',
      values: marginBlock,
      valueParser: getUnarySpacing,
    },
    {
      property: 'margin-inline',
      values: marginInline,
      valueParser: getUnarySpacing,
    },
    {
      property: 'margin-left',
      values: marginLeft,
      valueParser: getUnarySpacing,
    },
    {
      property: 'margin-right',
      values: marginRight,
      valueParser: getUnarySpacing,
    },
    {
      property: 'margin-top',
      values: marginTop,
      valueParser: getUnarySpacing,
    },
  ])}
`

export type PaddingProps = {
  padding?: ResponsiveStyleValue<Spacing>
  paddingBottom?: ResponsiveStyleValue<Spacing>
  paddingBlock?: ResponsiveStyleValue<Spacing>
  paddingInline?: ResponsiveStyleValue<Spacing>
  paddingLeft?: ResponsiveStyleValue<Spacing>
  paddingRight?: ResponsiveStyleValue<Spacing>
  paddingTop?: ResponsiveStyleValue<Spacing>
}

export const paddingProps = css<PaddingProps>`
  ${({
    padding,
    paddingBottom,
    paddingBlock,
    paddingInline,
    paddingLeft,
    paddingRight,
    paddingTop,
  }) => responsive([
    {
      property: 'padding',
      values: padding,
      valueParser: getUnarySpacing,
    },
    {
      property: 'padding-bottom',
      values: paddingBottom,
      valueParser: getUnarySpacing,
    },
    {
      property: 'padding-block',
      values: paddingBlock,
      valueParser: getUnarySpacing,
    },
    {
      property: 'padding-inline',
      values: paddingInline,
      valueParser: getUnarySpacing,
    },
    {
      property: 'padding-left',
      values: paddingLeft,
      valueParser: getUnarySpacing,
    },
    {
      property: 'padding-right',
      values: paddingRight,
      valueParser: getUnarySpacing,
    },
    {
      property: 'padding-top',
      values: paddingTop,
      valueParser: getUnarySpacing,
    },
  ])}
`

export type FlexProps = {
  alignItems?: ResponsiveStyleValue<
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset'
    | 'center'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'self-end'
    | 'self-start'
    | 'start'
    | 'baseline'
    | 'normal'
    | 'stretch'
  >
  alignSelf?: ResponsiveStyleValue<CSSProperties['alignSelf']>
  columnGap?: ResponsiveStyleValue<Spacing>
  display?: ResponsiveStyleValue<CSSProperties['display']>
  flex?: ResponsiveStyleValue<CSSProperties['flex']>
  flexDirection?: ResponsiveStyleValue<CSSProperties['flexDirection']>
  flexWrap?: ResponsiveStyleValue<CSSProperties['flexWrap']>
  gap?: ResponsiveStyleValue<Spacing>
  justifyContent?: ResponsiveStyleValue<CSSProperties['justifyContent']>
  rowGap?: ResponsiveStyleValue<Spacing>
}

export const flexProps = css<FlexProps>`
  ${({
    alignItems, alignSelf, columnGap, display, flexDirection, flexWrap, gap, justifyContent, rowGap,
  }) => responsive([
    {
      property: 'align-items',
      values: alignItems,
    },
    {
      property: 'align-self',
      values: alignSelf,
    },
    {
      property: 'column-gap',
      values: columnGap,
      valueParser: getUnarySpacing,
    },
    {
      property: 'display',
      values: display,
    },
    {
      property: 'flex-direction',
      values: flexDirection,
    },
    {
      property: 'flex-wrap',
      values: flexWrap,
    },
    {
      property: 'gap',
      values: gap,
      valueParser: getUnarySpacing,
    },
    {
      property: 'justify-content',
      values: justifyContent,
    },
    {
      property: 'row-gap',
      values: rowGap,
      valueParser: getUnarySpacing,
    },
  ])}
  ${({ flex }) => responsive([
    {
      property: 'flex',
      values: flex,
    },
  ])}
`

export type GridProps = {
  gridArea?: ResponsiveStyleValue<CSSProperties['gridArea']>
  gridAutoColumns?: ResponsiveStyleValue<CSSProperties['gridAutoColumns']>
  gridAutoFlow?: ResponsiveStyleValue<CSSProperties['gridAutoFlow']>
  gridAutoRows?: ResponsiveStyleValue<CSSProperties['gridAutoRows']>
  gridColumn?: ResponsiveStyleValue<CSSProperties['gridColumn']>
  gridRow?: ResponsiveStyleValue<CSSProperties['gridRow']>
  gridTemplateAreas?: ResponsiveStyleValue<CSSProperties['gridTemplateAreas']>
  gridTemplateColumns?: ResponsiveStyleValue<CSSProperties['gridTemplateColumns']>
  gridTemplateRows?: ResponsiveStyleValue<CSSProperties['gridTemplateRows']>
}

export const gridProps = css<GridProps>`
  ${({
    gridArea,
    gridAutoColumns,
    gridAutoFlow,
    gridAutoRows,
    gridColumn,
    gridRow,
    gridTemplateAreas,
    gridTemplateColumns,
    gridTemplateRows,
  }) => responsive([
    {
      property: 'grid-area',
      values: gridArea,
    },
    {
      property: 'grid-auto-columns',
      values: gridAutoColumns,
    },
    {
      property: 'grid-auto-flow',
      values: gridAutoFlow,
    },
    {
      property: 'grid-auto-rows',
      values: gridAutoRows,
    },
    {
      property: 'grid-column',
      values: gridColumn,
    },
    {
      property: 'grid-row',
      values: gridRow,
    },
    {
      property: 'grid-template-areas',
      values: gridTemplateAreas,
    },
    {
      property: 'grid-template-columns',
      values: gridTemplateColumns,
    },
    {
      property: 'grid-template-rows',
      values: gridTemplateRows,
    },
  ])}
`

export type BackgroundProps = {
  background?: ResponsiveStyleValue<CSSProperties['background']>
  backgroundColor?: ResponsiveStyleValue<Color | CSSProperties['backgroundColor']>
  backgroundImage?: ResponsiveStyleValue<CSSProperties['backgroundImage']>
  backgroundPosition?: ResponsiveStyleValue<CSSProperties['backgroundPosition']>
  backgroundRepeat?: ResponsiveStyleValue<CSSProperties['backgroundRepeat']>
  backgroundSize?: ResponsiveStyleValue<CSSProperties['backgroundSize']>
}

export const backgroundProps = css<BackgroundProps>`
  ${({
    background, backgroundImage, backgroundPosition, backgroundRepeat, backgroundSize,
  }) => responsive([
    {
      property: 'background',
      values: background,
    },
    {
      property: 'background-image',
      values: backgroundImage,
    },
    {
      property: 'background-position',
      values: backgroundPosition,
    },
    {
      property: 'background-repeat',
      values: backgroundRepeat,
    },
    {
      property: 'background-size',
      values: backgroundSize,
    },
  ])}
  ${({ backgroundColor }) => responsive([
    {
      property: 'background-color',
      values: backgroundColor,
      valueParser: getUnaryColor,
    },
  ])}
`

export type BorderProps = {
  border?: ResponsiveStyleValue<CSSProperties['border']>
  borderBottom?: ResponsiveStyleValue<CSSProperties['borderBottom']>
  borderBlock?: ResponsiveStyleValue<CSSProperties['borderBlock']>
  borderInline?: ResponsiveStyleValue<CSSProperties['borderInline']>
  borderLeft?: ResponsiveStyleValue<CSSProperties['borderLeft']>
  borderRight?: ResponsiveStyleValue<CSSProperties['borderRight']>
  borderTop?: ResponsiveStyleValue<CSSProperties['borderTop']>
  borderColor?: ResponsiveStyleValue<Color | CSSProperties['borderColor']>
  borderRadius?: ResponsiveStyleValue<BorderRadiusValue>
}

export const borderProps = css<BorderProps>`
  ${({
    border, borderBottom, borderBlock, borderInline, borderLeft, borderRight, borderTop,
  }) => responsive([
    {
      property: 'border',
      values: border,
      valueParser: (value) => value.toString().split(' ').map(getUnaryColor).join(' '),
    },
    {
      property: 'border-bottom',
      values: borderBottom,
    },
    {
      property: 'border-block',
      values: borderBlock,
    },
    {
      property: 'border-inline',
      values: borderInline,
    },
    {
      property: 'border-left',
      values: borderLeft,
    },
    {
      property: 'border-right',
      values: borderRight,
    },
    {
      property: 'border-top',
      values: borderTop,
    },
  ])}
  ${({ borderColor }) => responsive([
    {
      property: 'border-color',
      values: borderColor,
      valueParser: getUnaryColor,
    },
  ])}
  ${({ borderRadius }) => responsive([
    {
      property: 'border-radius',
      values: borderRadius,
      valueParser: getUnaryBorderRadius,
    },
  ])}
`

export type TextProps = {
  color?: Color | CSSProperties['color']
  fontFamily?: ResponsiveStyleValue<CSSProperties['fontFamily']>
  fontSize?: ResponsiveStyleValue<CSSProperties['fontSize']>
  fontStyle?: ResponsiveStyleValue<CSSProperties['fontStyle']>
  fontWeight?: ResponsiveStyleValue<CSSProperties['fontWeight']>
  letterSpacing?: ResponsiveStyleValue<CSSProperties['letterSpacing']>
  lineHeight?: ResponsiveStyleValue<CSSProperties['lineHeight']>
  textAlign?: ResponsiveStyleValue<CSSProperties['textAlign']>
  textDecoration?: ResponsiveStyleValue<CSSProperties['textDecoration']>
  textTransform?: ResponsiveStyleValue<CSSProperties['textTransform']>
}

export const textProps = css<TextProps>`
  color: ${({ color }) => getUnaryColor(color)};
  ${({
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    letterSpacing,
    lineHeight,
    textAlign,
    textDecoration,
    textTransform,
  }) => responsive([
    {
      property: 'font-family',
      values: fontFamily,
    },
    {
      property: 'font-size',
      values: fontSize,
    },
    {
      property: 'font-style',
      values: fontStyle,
    },
    {
      property: 'font-weight',
      values: fontWeight,
    },
    {
      property: 'letter-spacing',
      values: letterSpacing,
    },
    {
      property: 'line-height',
      values: lineHeight,
    },
    {
      property: 'text-align',
      values: textAlign,
    },
    {
      property: 'text-decoration',
      values: textDecoration,
    },
    {
      property: 'text-transform',
      values: textTransform,
    },
  ])}
`

export type DimensionProps = {
  aspectRatio?: ResponsiveStyleValue<CSSProperties['aspectRatio']>
  height?: ResponsiveStyleValue<CSSProperties['height']>
  maxHeight?: ResponsiveStyleValue<CSSProperties['maxHeight']>
  maxWidth?: ResponsiveStyleValue<CSSProperties['maxWidth']>
  minHeight?: ResponsiveStyleValue<CSSProperties['minHeight']>
  minWidth?: ResponsiveStyleValue<CSSProperties['minWidth']>
  width?: ResponsiveStyleValue<CSSProperties['width']>
}

export const dimensionProps = css<DimensionProps>`
  ${({
    aspectRatio, height, maxHeight, maxWidth, minHeight, minWidth, width,
  }) => responsive([
    {
      property: 'aspect-ratio',
      values: aspectRatio,
    },
    {
      property: 'height',
      values: height,
    },
    {
      property: 'max-height',
      values: maxHeight,
    },
    {
      property: 'max-width',
      values: maxWidth,
    },
    {
      property: 'min-height',
      values: minHeight,
    },
    {
      property: 'min-width',
      values: minWidth,
    },
    {
      property: 'width',
      values: width,
    },
  ])}
`

export type OverflowProps = {
  overflow?: ResponsiveStyleValue<CSSProperties['overflow']>
  overflowX?: ResponsiveStyleValue<CSSProperties['overflowX']>
  overflowY?: ResponsiveStyleValue<CSSProperties['overflowY']>
}

export const overflowProps = css<OverflowProps>`
  ${({ overflow, overflowX, overflowY }) => responsive([
    {
      property: 'overflow',
      values: overflow,
    },
    {
      property: 'overflow-x',
      values: overflowX,
    },
    {
      property: 'overflow-y',
      values: overflowY,
    },
  ])}
`

export type SxProps = {
  sx?: ((theme: Theme) => CSSObject) | CSSObject
}

export const sxProps = css<SxProps>`
  ${({ sx, theme }) => getSxProps(sx, theme)}
`

export const getSxProps = (sx: SxProps['sx'], theme: Theme) =>
  typeof sx === 'function' ? sx(theme) : sx

export type DynamicProps = BackgroundProps &
  BorderProps &
  DimensionProps &
  FlexProps &
  GridProps &
  MarginProps &
  PaddingProps &
  TextProps &
  OverflowProps &
  SxProps

export const dynamicProps = css<DynamicProps>`
  ${backgroundProps}
  ${borderProps}
  ${dimensionProps}
  ${flexProps}
  ${gridProps}
  ${marginProps}
  ${paddingProps}
  ${textProps}
  ${overflowProps}
  ${sxProps}
`
