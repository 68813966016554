import styled, { keyframes } from 'styled-components'
import { mediaQueries } from '~/assets/styles'

export const growAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    transform-origin: center;
  }
  50% {
    transform: scale(1.1);
    transform-origin: center;
  }
  100% {
    transform: scale(1);
    transform-origin: center;
  }
`

export const ScratchedWonRoot = styled.div`
  margin-top: 5rem;
  position: relative;

  & svg {
    width: 100%;

    @media ${mediaQueries.sm} {
      width: initial;
    }
  }

  & > .scratched-won__absolute-container {
    opacity: 0;
    animation: ${growAnimation} 0.9s 0.5s ease-in-out forwards;
    position: absolute;
    inset: 0;

    & > .scratched-won__stars {
      box-shadow: 0px -0px 1000px transparent;
      overflow: visible;

      position: absolute;
      left: 0px;
      top: -45px;

      @media ${mediaQueries.sm} {
        left: -35px;
      }

      & > path {
        animation: ${pulseAnimation} 1s 1.3s 2;
      }
    }

    & > .scratched-won__product {
      animation: ${growAnimation} 0.9s 0.5s ease-in-out forwards;
      opacity: 0;
      width: initial;

      position: absolute;
      bottom: -5%;
      right: 25px;

      @media ${mediaQueries.sm} {
        bottom: -20px;
      }
    }
  }
`

export const ScratchedWonAtom = {
  Root: ScratchedWonRoot,
}
