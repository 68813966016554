import { SVG, svgPaths } from '~/assets'
import { ScratchedWonAtom } from './ScratchedWon.styles'

export type ScratchedWonProps = {
  /**
   * The product image to be displayed
   * @default "A clover leaf image"
   */
  productImage?: {
    src: string
    alt: string
  }
}

export const ScratchedWon = (props: ScratchedWonProps) => {
  const { productImage = { alt: 'Trevo de 4 folhas', src: svgPaths.cloverLeaf } } = props

  return (
    <ScratchedWonAtom.Root>
      <SVG.RaspouGanhou />

      <div className="scratched-won__absolute-container">
        <SVG.Stars className="scratched-won__stars" />

        <img alt={productImage.alt} className="scratched-won__product" src={productImage.src} />
      </div>
    </ScratchedWonAtom.Root>
  )
}
