import styled, { keyframes } from 'styled-components'
import * as Tooltip from '@radix-ui/react-tooltip'
import {
  SxProps,
  borderRadius,
  colors,
  shadows,
  spacings,
  sxProps,
  typography,
} from '~/assets/styles'

const { grey } = colors

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

export type TooltipContentAtomProps = Tooltip.TooltipContentProps & SxProps

export const TooltipContentAtom = styled(Tooltip.Content)<TooltipContentAtomProps>`
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background-color: ${grey.darker};
  border-radius: ${borderRadius.small};
  box-shadow: ${shadows[1]};
  color: ${grey.white};
  font-size: ${typography.xSmall};
  padding: ${spacings[4]} ${spacings[8]};
  user-select: none;
  will-change: transform, opacity;

  &[data-state='delayed-open'] {
    &[data-side='top'] {
      animation-name: ${slideDownAndFade};
    }
    &[data-side='right'] {
      animation-name: ${slideLeftAndFade};
    }
    &[data-side='bottom'] {
      animation-name: ${slideUpAndFade};
    }
    &[data-side='left'] {
      animation-name: ${slideRightAndFade};
    }
  }

  ${sxProps}
`

export const TooltipArrowAtom = styled(Tooltip.Arrow)`
  fill: ${grey.darker};
`
