import ScratchCard from 'react-scratchcard-v2'

import useDialog from '~/utils/useDialog'
import { Services } from '~/services'

import { Button, Flex, Icon, Typography } from '~/ds-components'

import ScratchCardImage from '../../assets/img/raspadinha/scratchCardImage.png'
import { spacings } from '~/assets/styles'
import { ScratchCardContent } from './styles'

type ScratchCardComponentProps = {
  value: string
  maxValue: string
  contractNumber: string
  slug: string
}

export const ScratchCardComponent = (props: ScratchCardComponentProps) => {
  const { value, maxValue, contractNumber, slug } = props

  const { show } = useDialog()

  const handleOnComplete = async () => {
    const dialogId = 'raspadinha-concluida'

    show({
      dialogId,
      header: {
        title: '',
        sx: { marginBottom: 0, border: 'none', padding: `${spacings[8]} ${spacings[8]} 0` },
      },

      content: (
        <Flex gap={16} alignItems="center" flexDirection="column">
          <Icon name="Celebration" size={48} color="green" />

          <Typography color="gray.700" variant="xLarge" sx={{ fontWeight: 600 }}>
            {`Parabéns, você ganhou ${value}!`}
          </Typography>

          <Typography color="gray.600" textAlign="center">
            O valor do Prêmio será enviado para a sua loja e, posteriormente, repassado pela mesma
            para você.
          </Typography>

          <Typography color="gray.600" textAlign="center">
            Você poderá ver mais detalhes sobre seu Prêmio no Sistema Acerta!
          </Typography>

          <Button
            size="medium"
            as="a"
            href={`${process.env.REACT_APP_SISTEMA_ACERTA_URL}/meus-premios`}
            target="_blank"
          >
            Ir para “Meus Prêmios”
          </Button>
        </Flex>
      ),
      slotProps: { body: { sx: { paddingTop: 0 } } },
      size: 'sm',
    })

    await Services.setViewed(contractNumber, slug)
  }

  return (
    <Flex gap={8} alignItems="center" flexDirection="column">
      <Flex gap={6} alignItems="center">
        <Typography color="white" variant="3xLarge" bold>
          GANHE ATÉ
        </Typography>

        <Typography color="white" variant="5xLarge" sx={{ fontWeight: 700 }}>
          {maxValue}
        </Typography>
      </Flex>

      <ScratchCard
        image={ScratchCardImage}
        width={300}
        height={140}
        brushSize={24}
        finishPercent={70}
        onComplete={() => setTimeout(() => handleOnComplete(), 1_500)}
      >
        <ScratchCardContent>
          <Typography color="white" variant="7xLarge" bold>
            {value}
          </Typography>
        </ScratchCardContent>
      </ScratchCard>
    </Flex>
  )
}
