export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 1920,
} as const

export type Breakpoints = typeof breakpoints
export type Breakpoint = keyof Breakpoints
export type BreakpointValue = Breakpoint | (string & {})

export const mediaQueries = {
  xs: `(min-width: ${breakpoints.xs}px)`,
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  xxl: `(min-width: ${breakpoints.xxl}px)`,
  xxxl: `(min-width: ${breakpoints.xxxl}px)`,
} as const

export const getUnaryBreakpoint = (breakpoint?: BreakpointValue) => {
  if (!breakpoint || !(breakpoint in breakpoints)) return breakpoint

  return `${breakpoints[breakpoint as Breakpoint]}px`
}
