import { GetPrizeStocksInterface } from '~/models/interfaces/services'

import api from '../config/api'

const basePathPrizeDrawV2 = '/v2/prize-draw'

const getPrizeStocks = async (contractNumber: string, slug: string) => {
  const response = await api.get<GetPrizeStocksInterface>(
    `${basePathPrizeDrawV2}/queries/contract/${contractNumber}/commercial-campaign/${slug}/prize-stocks`,
  )

  return response
}

const setViewed = async (contractNumber: string, slug: string) => {
  const response = await api.put(
    `${basePathPrizeDrawV2}/contract/${contractNumber}/commercial-campaign/${slug}/viewed`,
  )

  return response
}

export const Services = {
  getPrizeStocks,
  setViewed,
}
