export const typography = {
  '8xLarge': '5.6rem',
  '7xLarge': '4.8rem',
  '6xLarge': '4rem',
  '5xLarge': '3.6rem',
  '4xLarge': '3.2rem',
  '3xLarge': '2.8rem',
  '2xLarge': '2.4rem',
  xLarge: '2rem',
  large: '1.8rem',
  medium: '1.6rem',
  small: '1.4rem',
  xSmall: '1.2rem',
  '2xSmall': '1rem',
} as const

export type Typography = typeof typography

export type TypographyKeys = keyof Typography

export const typographyKeysString = Object.keys(typography).join(' | ')
