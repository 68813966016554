const highlightColors = {
  gold: {
    10: '#FFFFFF',
    50: '#FFF8E6',
    100: '#FFF1CC',
    200: '#FFE299',
    300: '#FFD466',
    400: '#FFC533',
    500: '#FFB700',
    600: '#CC9200',
    700: '#996E00',
    800: '#664900',
    900: '#4D3700',
  },
  purple: {
    10: '#FFFFFF',
    50: '#F3EBF9',
    100: '#E7D8F3',
    200: '#D0B1E7',
    300: '#B88ADB',
    400: '#A063CF',
    500: '#893CC3',
    600: '#6D309C',
    700: '#522475',
    800: '#37184E',
    900: '#29123b',
  },
  teal: {
    10: '#FFFFFF',
    50: '#E8FBFC',
    100: '#D4F0F7',
    200: '#A8E1F0',
    300: '#7DD3E8',
    400: '#52C4E0',
    500: '#26B5D9',
    600: '#219EBC',
    700: '#176D82',
    800: '#0F4857',
    900: '#0B3641',
  },
  turquoise: {
    10: '#FFFFFF',
    50: '#E8FBFC',
    100: '#D2F8F9',
    200: '#A5F1F3',
    300: '#78E9ED',
    400: '#4AE2E8',
    500: '#1AC0C6',
    600: '#17AFB5',
    700: '#128387',
    800: '#0C585A',
    900: '#094244',
  },
} as const

const primaryColors = {
  blue: {
    10: '#F8FAFF',
    50: '#EAEEFB',
    100: '#D5DDF6',
    200: '#AABBEE',
    300: '#8099E5',
    400: '#5677DC',
    500: '#274CBF',
    600: '#2344A9',
    700: '#1A337F',
    800: '#112255',
    900: '#09112A',
  },
  orange: {
    10: '#F5F6FA',
    50: '#FFF2E6',
    100: '#FFE6CC',
    200: '#FFCC99',
    300: '#FFB366',
    400: '#FF9933',
    500: '#FF8000',
    600: '#CC6600',
    700: '#994D00',
    800: '#663300',
    900: '#331A00',
  },
} as const

const neutralColors = {
  gray: {
    10: '#F6F7F9',
    50: '#EFF2F5',
    100: '#DFE4EC',
    200: '#BFCAD9',
    300: '#9FAFC6',
    400: '#8095B3',
    500: '#607A9F',
    600: '#4D6280',
    700: '#394960',
    800: '#212A37',
    900: '#131820',
  },
  white: '#FFFFFF',
} as const

const secondaryColors = {
  error: {
    10: '#F6F7F9',
    50: '#FFECEB',
    100: '#FF9B99',
    200: '#FF6966',
    300: '#FF6966',
    400: '#FF3633',
    500: '#FF0400',
    600: '#CC0500',
    700: '#990300',
    800: '#660200',
    900: '#330100',
  },
  info: {
    10: '#FFFFFF',
    50: '#E6EEFF',
    100: '#CCDDFF',
    200: '#99BBFF',
    300: '#6699FF',
    400: '#3377FF',
    500: '#1966FF',
    600: '#0044CC',
    700: '#003399',
    800: '#002266',
    900: '#001A4D',
  },
  success: {
    10: '#FFFFFF',
    50: '#EFFBF1',
    100: '#D6F5DB',
    200: '#ADEBB8',
    300: '#85E094',
    400: '#5CD670',
    500: '#33CC4C',
    600: '#29A33D',
    700: '#1F7A2E',
    800: '#14521F',
    900: '#0A290F',
  },
  warning: {
    10: '#FFFFFF',
    50: '#FFFCEB',
    100: '#FFF6CC',
    200: '#FFEE99',
    300: '#FFE666',
    400: '#FFDD33',
    500: '#FFD500',
    600: '#CCAA00',
    700: '#998000',
    800: '#665500',
    900: '#332B00',
  },
} as const

export const colors = {
  ...highlightColors,
  ...primaryColors,
  ...neutralColors,
  ...secondaryColors,
  primary: {
    darker: '#1A337F',
    dark: '#2344A9',
    base: '#274CBF',
    light: '#D5DDF6',
    lighter: '#F2F5FF',
  },
  secondary: {
    darker: '#994D00',
    dark: '#FF8000',
    base: '#FF9933',
    light: '#FFE6CC',
    lighter: '#FFF2E6',
  },
  grey: {
    darker: '#212A37',
    dark: '#394960',
    base: '#4D6280',
    light: '#BFCAD9',
    lighter: '#DFE4EC',
    lightest: '#EFF2F5',
    blueish: '#F5F6FA',
    white: '#FFFFFF',
  },
  error: {
    darker: '#990300',
    dark: '#CC0500',
    base: '#FF3833',
    light: '#FF9B99',
    lighter: '#FFECEB',
    ...secondaryColors.error,
  },
  warning: {
    darker: '#998000',
    dark: '#CCAA00',
    base: '#FFDD33',
    light: '#FFEE99',
    lighter: '#FFFCEB',
    ...secondaryColors.warning,
  },
  success: {
    darker: '#1F7A2E',
    dark: '#29A33D',
    base: '#5CD670',
    light: '#ADEBB8',
    lighter: '#EFFBF1',
    ...secondaryColors.success,
  },
  info: {
    darker: '#003399',
    dark: '#0044CC',
    base: '#1966FF',
    light: '#99BBFF ',
    lighter: '#E6EEFF ',
    ...secondaryColors.info,
  },
  background: {
    grey: '#F4F5F7',
    system: '#F5F6FA',
    card: '#F3F9FF',
    white: '#FFFFFF',
  },
} as const

export const getV1Colors = () => {
  const { background, blue, gold, gray, orange, purple, teal, turquoise, white, ...restColors } =
    colors
  return restColors
}

export type Colors = Omit<typeof colors, 'background'>
export type ColorsKeys = keyof Colors

type ColorsKeyOf = {
  [K in keyof typeof colors]: (typeof colors)[K] extends Record<string | number, unknown>
    ? `${K}.${Extract<keyof (typeof colors)[K], string | number>}`
    : never
}[keyof typeof colors]

export type Color = ColorsKeyOf | (string & {})

export const getUnaryColor = (color?: Color) => {
  if (!color?.includes('.')) return color
  const splittedColor = color.split('.')

  const [key, value] = splittedColor as [ColorsKeys, keyof Colors[ColorsKeys]]

  if (!colors[key]) return color

  return colors[key][value]
}
