import { useRef } from 'react'
import { colors, Breakpoint, SxProps } from '~/assets/styles'
import { LoaderWrapper } from './styles'
import { makeId } from '~/ds-components/helpers'

type LoaderBreakpoint = Exclude<Breakpoint, 'xl' | 'xxl' | 'xxxl'>

export type LoaderProps = {
  variant?: 'primary' | 'light'
  size?: LoaderBreakpoint
  svgProps?: SVGProps
} & SxProps

export const Loader = ({ variant = 'primary', size = 'lg', svgProps, ...rest }: LoaderProps) => {
  const { current: id } = useRef(makeId())

  return (
    <LoaderWrapper size={sizeMapping[size || 'lg']} data-testid="loader" {...rest}>
      {variant === 'primary' ? (
        <PrimaryCircularSVG id={id} {...svgProps} />
      ) : (
        <LightCircularSVG id={id} {...svgProps} />
      )}
    </LoaderWrapper>
  )
}

const { grey, primary } = colors

const sizeMapping: Record<LoaderBreakpoint, number> = {
  xs: 16,
  sm: 24,
  md: 40,
  lg: 80,
}

type SVGProps = {
  id: string
}

const PrimaryCircularSVG = ({ id }: SVGProps) => {
  const paintIds = {
    0: `paint0_linear_${id}`,
    1: `paint1_linear_${id}`,
  }

  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM12 40C12 55.464 24.536 68 40 68C55.464 68 68 55.464 68 40C68 24.536 55.464 12 40 12C24.536 12 12 24.536 12 40Z"
        fill={`url(#${paintIds[0]})`}
      />
      <path
        d="M74 40C77.3137 40 80.046 37.2989 79.5508 34.0224C79.0678 30.8261 78.1979 27.6928 76.9552 24.6927C74.945 19.8396 71.9986 15.4301 68.2843 11.7157C64.5699 8.00138 60.1604 5.05501 55.3073 3.04482C52.3072 1.80211 49.1739 0.932242 45.9776 0.449162C42.7011 -0.0460383 40 2.68629 40 6C40 9.31371 42.7163 11.9357 45.9542 12.6404C47.5785 12.9939 49.172 13.4922 50.7151 14.1314C54.1123 15.5385 57.1989 17.601 59.799 20.201C62.399 22.8011 64.4615 25.8877 65.8686 29.2849C66.5078 30.828 67.0061 32.4215 67.3596 34.0458C68.0643 37.2837 70.6863 40 74 40Z"
        fill={`url(#${paintIds[1]})`}
      />
      <defs>
        <linearGradient
          id={paintIds[0]}
          x1="40"
          y1="1.19209e-06"
          x2="80"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primary.base} />
          <stop offset="1" stopColor={primary.base} stopOpacity="0.25" />
        </linearGradient>
        <linearGradient
          id={paintIds[1]}
          x1="80"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primary.base} />
          <stop offset="1" stopColor={primary.base} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const LightCircularSVG = ({ id }: SVGProps) => {
  const paintIds = {
    0: `paint0_linear_${id}`,
    1: `paint1_linear_${id}`,
  }

  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM12 40C12 55.464 24.536 68 40 68C55.464 68 68 55.464 68 40C68 24.536 55.464 12 40 12C24.536 12 12 24.536 12 40Z"
        fill={`url(#${paintIds[0]})`}
      />
      <path
        d="M74 40C77.3137 40 80.046 37.2989 79.5508 34.0224C79.0678 30.8261 78.1979 27.6928 76.9552 24.6927C74.945 19.8396 71.9986 15.4301 68.2843 11.7157C64.5699 8.00138 60.1604 5.05501 55.3073 3.04482C52.3072 1.80211 49.1739 0.932242 45.9776 0.449162C42.7011 -0.0460383 40 2.68629 40 6C40 9.31371 42.7163 11.9357 45.9542 12.6404C47.5785 12.9939 49.172 13.4922 50.7151 14.1314C54.1123 15.5385 57.1989 17.601 59.799 20.201C62.399 22.8011 64.4615 25.8877 65.8686 29.2849C66.5078 30.828 67.0061 32.4215 67.3596 34.0458C68.0643 37.2837 70.6863 40 74 40Z"
        fill={`url(#${paintIds[1]})`}
      />
      <defs>
        <linearGradient
          id={paintIds[0]}
          x1="40"
          y1="1.19209e-06"
          x2="80"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={grey.blueish} />
          <stop offset="1" stopColor="white" stopOpacity="0.25" />
        </linearGradient>
        <linearGradient
          id={paintIds[1]}
          x1="80"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={grey.blueish} />
          <stop offset="1" stopColor={grey.blueish} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
