import styled, { css } from 'styled-components'
import { ButtonAtom, ButtonAtomProps } from '../Button/styles'
import { getUnaryColor, spacings } from '~/assets/styles'

export type IconButtonAtomProps = {
  iconColor?: string
  iconHoverColor?: string
} & ButtonAtomProps

const iconButtonStyles = css<IconButtonAtomProps>`
  & > svg {
    color: ${({ iconColor }) => getUnaryColor(iconColor) || 'inherit'};
    ${({ size }) => sizes[size || 'medium']}
  }
  &:hover > svg {
    color: ${({ iconHoverColor }) => getUnaryColor(iconHoverColor) || 'inherit'};
  }
`

export const IconButtonAtom = styled(ButtonAtom)`
  padding: ${spacings[8]};

  ${({ variant }) =>
    variant === 'link' &&
    css`
      padding: ${spacings[4]};
    `}
  ${iconButtonStyles};
`

const sizes = {
  medium: css`
    width: 24px;
    height: 24px;
  `,
  small: css`
    width: 16px;
    height: 16px;
  `,
  smaller: css`
    width: 12px;
    height: 12px;
  `,
  large: css`
    width: 32px;
    height: 32px;
  `,
  mobile: css`
    width: 32px;
    height: 32px;
  `,
}
