import { forwardRef } from 'react'
import { Root, Close, Portal, PopoverPortalProps } from '@radix-ui/react-popover'

import { Icon } from '../../atoms/Icon'

import {
  PopoverArrowAtom,
  PopoverArrowAtomProps,
  PopoverCloseAtom,
  PopoverCloseAtomProps,
  PopoverContentAtom,
  PopoverContentAtomProps,
  PopoverRootAtomProps,
  PopoverTriggerAtom,
  PopoverTriggerAtomProps,
} from './styles'

export type PopoverProps = PopoverRootAtomProps
export const Popover = Root

export type PopoverTriggerProps = PopoverTriggerAtomProps

export const PopoverTrigger = forwardRef<HTMLButtonElement, PopoverTriggerAtomProps>(
  (props, ref) => {
    const { children, ...rest } = props

    return (
      <PopoverTriggerAtom ref={ref} data-testid="popover-trigger" {...rest} asChild>
        {children}
      </PopoverTriggerAtom>
    )
  },
)
PopoverTrigger.displayName = 'PopoverTrigger'

export type PopoverCloseProps = PopoverCloseAtomProps
export const PopoverClose = Close

export type PopoverContentProps = {
  showArrow?: boolean
  showClose?: boolean
  slotProps?: {
    close?: PopoverCloseAtomProps
    arrow?: PopoverArrowAtomProps
    portal?: PopoverPortalProps
  }
} & PopoverContentAtomProps

const rootElement = document.getElementById('root')

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  (props, forwardedRef) => {
    const { children, showArrow = true, showClose = true, slotProps, ...rest } = props

    return (
      <Portal container={rootElement} {...slotProps?.portal}>
        <PopoverContentAtom sideOffset={5} {...rest} ref={forwardedRef}>
          {children}

          {showClose && (
            <PopoverCloseAtom {...slotProps?.close}>
              <Icon name="Close" size={18} />
            </PopoverCloseAtom>
          )}

          {showArrow && <PopoverArrowAtom {...slotProps?.arrow} />}
        </PopoverContentAtom>
      </Portal>
    )
  },
)
